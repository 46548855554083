import en from './static/lang/en'
import bg from './static/lang/bg'
import es from './static/lang/es'

// Default language
const defaultLocale = 'en'

// List of available languages
const locales = [
  {
    code: 'en',
    iso: 'en-US',
    name: 'English'
    // isCatchallLocale: true
  },
  {
    code: 'bg',
    iso: 'bg-BG',
    name: 'Bulgarian'
    // isCatchallLocale: true
  },
  // Added Spanish
  {
    code: 'es',
    iso: 'es-ES',
    name: 'Español'
    // isCatchallLocale: true
  }
]

// List of available message languages
const i18nLocales = {
  fallbackLocale: 'en',
  messages: { en, bg, es } // Include Spanish messages
}

// List of available page routes in different languages
const i18nPages = {
  'blog/_slug': {
    en: '/blog/:slug/',
    bg: '/blog/:slug/',
    es: '/blog/:slug/',
  },
  'blog/topics/_slug': {
    en: '/blog/topics/:slug/',
    bg: '/blog/kategorii/:slug/',
    es: '/blog/topics/:slug/',
  },
  'support/_slug': {
    en: '/support/:slug/',
    bg: '/pomoshten-centar/:slug/',
    es: '/soporte/:slug/',
  },
  'support/topics/_slug': {
    en: '/support/topics/:slug/',
    bg: '/support/kategorii/:slug/',
    es: '/support/topics/:slug/',
  },
  'integrations/_slug': {
    en: '/integrations/:slug/',
    bg: '/integracii/:slug/',
    es: '/integraciones/:slug/',
  },
  'use-cases/_slug': {
    en: '/use-cases/:slug/',
    bg: '/primeri-za-polzvane/:slug/',
    es: '/casos-de-uso/:slug/',
  },
  'templates/_slug': {
    en: '/templates/:slug/',
    bg: '/templeiti/:slug/',
    es: '/plantillas/:slug/',
  },
  'comparison/_slug': {
    en: '/comparison/:slug/',
    bg: '/sravnenie/:slug/',
    es: '/comparar/:slug/',
  },
}

export { defaultLocale, locales, i18nLocales, i18nPages }
