// layouts/commonLayoutMixin.js
import { debounce } from 'throttle-debounce';

export default {
  mounted() {
    this.updateIsMobileState();
    this.handleResizeDebounced = debounce(200, this.handleResize, { atBegin: false });
    window.addEventListener('resize', this.handleResizeDebounced);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResizeDebounced);
  },
  methods: {
    updateIsMobileState() {
      // Use your device check and window.innerWidth logic
      const isMobile = this.$device.isMobile && window.innerWidth < 768;
      this.$store.dispatch('updateIsMobile', isMobile);
    },
    handleResize() {
      this.updateIsMobileState();
    },
  },
  head() {
    // If you want to share your head logic, you can put it here.
    const i18nHead = this.$nuxtI18nHead({ addSeoAttributes: true });
    const processedLinks = i18nHead.link.map((link) => {
      if (
        (link.rel === 'alternate' || link.rel === 'canonical') &&
        link.href &&
        !link.href.endsWith('/')
      ) {
        return { ...link, href: `${link.href}/` };
      }
      return link;
    });
    return {
      htmlAttrs: { ...i18nHead.htmlAttrs },
      meta: [
        ...i18nHead.meta,
        {
          hid: 'description',
          name: 'description',
          content: this.$config.defaultDescription,
        },
        { hid: 'og:image', property: 'og:image', content: this.$config.defaultImage },
        { hid: 'twitter:image', name: 'twitter:image', content: this.$config.defaultImage },
        { hid: 'twitter:card', name: 'twitter:card', content: 'summary_large_image' },
        { hid: 'og:image:width', property: 'og:image:width', content: '1200' },
        { hid: 'og:image:height', property: 'og:image:height', content: '628' },
      ],
      link: [
        ...processedLinks,
        { rel: 'preconnect', href: 'https://fonts.googleapis.com' },
        { rel: 'preconnect', href: 'https://fonts.gstatic.com', crossorigin: true },
        {
          rel: 'prefetch',
          href: 'https://app.onvocado.com/register',
          as: 'document',
        },
        {
          rel: 'alternate',
          type: 'application/rss+xml',
          title: 'RSS Feed',
          href: `${this.$config.baseUrl}/feed.xml`,
        },
      ],
      script: process.env.APP_LIVE === 'true'
        ? [
            {
              src: 'https://www.googletagmanager.com/gtag/js?id=G-HPM50ZRLTD',
              async: true,
            },
            { src: '/js/ga.js', async: true },
            {
              src: 'https://load.fomo.com/api/v1/JAFTPu27bKRFNAKyZVdgaw/load.js',
              type: 'text/javascript',
              async: true,
            },
          ]
        : [],
    };
  },
};
