
import commonLayoutMixin from '~/layouts/commonLayoutMixin';

export default {
  name: 'DefaultLayout',
  mixins: [commonLayoutMixin],
  head() {
    // Call the common mixin's head method to get the default head configuration
    const commonHead = commonLayoutMixin.head.call(this);

    // Add the additional script only for the default layout
    commonHead.script.push({
      src: 'https://load.fomo.com/api/v1/JAFTPu27bKRFNAKyZVdgaw/load.js',
      type: 'text/javascript',
      async: true,
    });

    return commonHead;
  },
};
